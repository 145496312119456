/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-top-center {
  top: 65px !important;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #ffffff;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: #51a351;
}
.toast-error {
  background-color: #bd362f;
}
.toast-info {
  background-color: #2f96b4;
}
.toast-warning {
  background-color: #f89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
.modal-tos .modal-dialog {
  max-width: 700px;
  transform: translate(-50%, 34%);
  left: 50%;
  top: 0;
  margin: 0;
}
.zip-modal .modal-dialog {
  max-width: 1700px;
  transform: translate(-50%, 4%);
  max-height: 800px;
  height: 800px;
  left: 50%;
  top: 0;
  margin: 0;
}
.zip-modal .modal-content {
  height: 100%;
}
.modal-tos .modal-header h5.modal-title {
  font-size: 2em;
  width: 100%;
  text-align: center;
  margin-top: 0 !important;
  margin-bottom: 20px !important;
  color: #2993c7;
  font-weight: 500;
  line-height: 1.1;
}
.modal-tos .modal-header {
  padding: 0;
  border: 0;
}
.modal-tos .modal-body .img-container-block {
  text-align: center;
}
.modal-tos .modal-content {
  padding: 25px;
}
.modal-tos .modal-dialog .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.modal-tos .modal-dialog p {
  color: #7e7e7e;
  line-height: 1.33em;
}
.modal-tos .modal-dialog .modal-footer {
  border: 0;
}
#terms::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
#terms::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 10px;
  height: 55px;
}
#terms::-webkit-scrollbar-track {
  background-color: #fff;
}
.quota-modal .modal-header .btn-close {
  position: absolute;
  right: 30px;
  padding: 15px;
}
.quota-modal .modal-header .btn-close:focus {
  box-shadow: none;
}
.quota-modal .modal-header .btn-close:hover {
  background-color: #e8e8e8;
  border-radius: 50%;
}

.quota-modal .modal-header {
  border-bottom: none;
  margin-top: 10px;
}
.quota-modal:focus {
  outline: none;
}
.quota-modal:focus *,
.cdk-overlay-container:focus *,
.quota-modal *:focus {
  outline: none;
  box-shadow: none;
}
.quota-modal .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: auto;
}

.greyOutBackGround {
  background: #e0e0e0 !important;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px !important;
  }
}

@media (max-width: 500px) {
  /*PD-9053*/
  body {
    overflow-x: hidden;
  }
}

form.report-form .list-select .ng-select .ng-select-container {
  height: 64px;
  width: 100%;
  font-family: OpenSans-Semibold;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
/* tooltip css */
.tootip-setting-info {
  font-size: 24px;
  color: #4a91c5;
  z-index: 2;
  display: inline-block;
  margin-left: 15px;
}

.tootip-setting-info p,
.tootip-setting-info .tooltip-content {
  display: none;
  text-align: left;
  background-color: #1e2021;
  padding: 20px;
  width: 300px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 13px;
  line-height: 1.4;
  z-index: 9999999;
}

.tootip-setting-info p:before,
.tootip-setting-info .tooltip-content:before {
  /* The pointer of the tooltip */
  position: relative;
  content: '';
  width: 0;
  height: 0;
  right: 10px;
  top: -12px;
  z-index: 9999999;
  border: none;
}

.tootip-setting-info p:after,
.tootip-setting-info .tooltip-content:after {
  /* Prevents the tooltip from being hidden */
  left: 0;
  top: 100%;
  padding: 10px;
  width: 100%;
  height: 40px;
  content: '';
  position: relative;
  top: -40px;
  left: 0;
  z-index: 9999999;
}

.tooltip-visible:hover p,
.tooltip-visible:hover .tooltip-content {
  display: block;
  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
  overflow-wrap: break-word !important;
}

@media (max-width: 767px) {
  .quota-modal .modal-header .btn-close {
    right: 15px;
    padding: 15px;
    top: 10px;
  }
}
.setting-info {
  font-size: 20px;
  color: #4a91c5;
  z-index: 2;
  cursor: pointer;
  display: inline-block;
  margin-left: 15px;
}
.setting-info i {
  font-size: 20px;
  color: #4a91c5;
  z-index: 2;
  cursor: pointer;
}

.setting-info:hover p {
  display: block;
  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
  overflow-wrap: break-word !important;
}

.setting-info p {
  display: none;
  text-align: left;
  background-color: #1e2021;
  padding: 20px;
  width: 300px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 13px;
  line-height: 1.4;
  z-index: 9999999;
}

.setting-info p:before {
  /* The pointer of the tooltip */
  position: relative;
  content: '';
  width: 0;
  height: 0;
  right: 10px;
  top: -12px;
  z-index: 9999999;
  border: none;
}

.setting-info p:after {
  /* Prevents the tooltip from being hidden */
  left: 0;
  top: 100%;
  padding: 10px;
  width: 100%;
  height: 40px;
  content: '';
  position: relative;
  top: -40px;
  left: 0;
  z-index: 9999999;
}

/*==== 18/11/2021 =====*/

.new-drp-changer ul.dropdown-menu.user_list_dropdown_menu {
  width: 100% !important;
  top: 6px !important;
  left: 0px !important;
}

.show-calendar.daterangepicker .drp-buttons .btn.applyBtn {
  background-color: #337ab7;
  border-color: #2e6da4;
}
.new-com-vs button.dropdown-button {
  border: none;
  padding: 6px 2px;
  font-size: 15px;
}
.form-control.new-drp-changer {
  padding: 0;
}

.basic-size-with-default-color .dropdown-item {
  display: block;
  font-size: 14px;
  width: 100%;
  clear: both;
  color: #337ab7;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  padding: 0rem 1rem;
  border: 0;
}

.basic-size-with-default-color .dropdown-item:hover {
  color: #23527c;
}

.basic-size-with-black-color .dropdown-item {
  color: #555;
  font-size: 14px;
  padding: 0rem 1rem;
}

.fix-size-block .dropdown-button {
  height: 36px !important;
  padding: 0 !important;
}

.fix-size-block-padding .dropdown-button {
  height: 36px !important;
  padding: 0 10px !important;
}

.form-horizontal-select-dropdown label {
  font-size: 15px;
  color: #707070;
  margin-bottom: 10px;
  font-weight: 500;
}
.form-horizontal-select-dropdown button,
.ans-textarea textarea {
  height: 40px !important;
  color: #333 !important;
  border-color: #ccc !important;
  padding: 6px 12px !important;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left !important;
  border-radius: 4px !important;
}
.form-horizontal-select-dropdown .col-md-6 {
  margin-bottom: 15px;
}
button.add-resp-id-btn {
  float: right;
  margin-bottom: 5px;
  background: #4a91c5;
  color: #fff !important;
  font-family: OpenSans-Semibold;
  font-size: 14px;
  padding: 6px 25px !important;
  text-transform: none;
  border: 1px solid #4a91c5;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  border-radius: 0 !important;
}

.ans-text h3 {
  font-size: 15px;
  color: #707070;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
}
.plus-btn button.btn.add_input_btn {
  width: 100%;
  background: #179fe2;
  color: #fff !important;
  border-color: #179fe2;
  height: 40px;
  border-radius: 0;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center !important;
}
.btn-left button {
  float: left;
}
.row.next-btn-div {
  padding-top: 20px;
  border-top: 1px solid #ccc;
  margin-top: 20px;
}
.btn-left {
  padding-left: 0;
}
.btn-right {
  padding-right: 0;
}
.question_form .accordion-header button span.ng-star-inserted {
  color: #fff;
}
.adv-setting-accordion .accordion-item:last-of-type {
  border: 0;
}
.adv-setting-accordion
  .accordion-item:last-of-type
  button.accordion-button:focus {
  box-shadow: inherit;
  background-color: inherit;
  color: #212529;
}
.adv-setting-accordion .accordion-item:first-of-type .accordion-button {
  background-color: inherit;
  color: #212529;
}
.adv-setting-accordion
  .accordion-item:last-of-type
  button.accordion-button.collapsed
  button.accordion-chevron {
  transform: rotate(270deg);
}
.adv-setting-accordion
  .accordion-item:last-of-type
  button.accordion-button
  button.accordion-chevron {
  transform: rotate(360deg);
}
.select_box_nc button {
  padding: 14px 35px 14px 10px !important;
  text-align: left !important;
}
.select_box_nc .user_list_dropdown_menu.show {
  width: 100% !important;
  right: 0;
  top: 0;
}

.accordian-new-localization-1 h2 button.accordion-button .accordian-inner-span {
  display: flex;
  width: 75%;
  flex: 75%;
  align-items: center;
}

.accordian-new-localization-1 h2 button.accordion-button span {
  color: #707070 !important;
  font-weight: 500;
}
.accordian-new-localization-1
  h2
  button.accordion-button
  .accordian-inner-span
  span {
  font-size: 15px;
  padding-right: 15px;
  line-height: 1.1;
}
.accordian-new-localization-1 h2 button.accordion-button {
  background: #fff;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
span.accordian-inner-span-254 {
  width: 15%;
  flex: 15%;
  justify-content: flex-end;
  display: flex;
  padding-right: 10px;
  font-size: 15px;
}
span.accordian-inner-span-2,
span.accordian-inner-span-3 {
  width: 10%;
  flex: 10%;
  justify-content: flex-end;
  display: flex;
  padding-right: 10px;
}
span.accordian-inner-span-3 button.btn.btn-danger {
  width: 65px;
  background-color: #d9534f;
  border-color: #d43f3a;
  border-radius: 0;
}
span.accordian-inner-span-2 button.btn.btn-primary {
  width: 65px;
  border-radius: 0;
}
.accordian-new-localization-1 h2 button.accordion-button i.fa.fa-chevron-down {
  color: #333;
}
.modal-open {
  overflow: hidden !important;
}

.ng-dropdown-panel .scroll-host {
  position: absolute !important;
  width: 100% !important;
}

/* this is made global intentionally so whenever we want to add the pointer cursor we can use it to any component */
.pointer-cursor {
  cursor: pointer;
}

.project-manager-dropdown
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option {
  height: 22px;
  padding: 0px 18px 23px 18px;
}

.project-manager-dropdown
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option:hover {
  background-color: #1b9dec;
  color: #fff;
}
.date-range {
  height: 69%;
  border: 1px solid #d7d7d7;
  text-align: center;
  color: #606365;
}

.manage-select-dropdown .dropdown-button {
  text-transform: capitalize !important;
}
.ps-sky {
  background-color: #169fe2 !important;
}

.ps-sky-info i {
  color: #31b2f1 !important;
}
.report-table .table-absolute table.table {
  position: unset;
}
.advance-targeting-modal .modal-body form {
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}
.advance-targeting-modal .modal-body form .justify-content-between {
  align-items: center;
}
.adv-dropdown {
  width: 62%;
  position: relative;
  display: inline-block;
  margin-left: 5px;
}
.adv-dropdown ng-select#search {
  min-width: auto;
}
.adv-dropdown ng-select#search .ng-select-container {
  width: 100%;
  padding: 14px 60px 12px 25px;
  height: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.adv-dropdown ng-select#search .ng-select-container .ng-value-container {
  padding-left: 0;
}
.adv-dropdown ng-select#search .ng-select-container span.ng-arrow-wrapper {
  display: none;
}

.adv-dropdown span.browse-text {
  position: absolute;
  top: 17px;
  right: 10px;
  font-size: 11px;
}
.adv-dropdown
  ng-select#search
  .ng-select-container
  .ng-value-container
  .ng-input {
  top: 13px;
}
.adv-dropdown ng-select#search span.ng-clear-wrapper {
  display: none;
}
.advance-targeting-modal {
  position: absolute;
  top: 15%;
  width: 600px;
}
.advance-targeting-modal .modal-body form label.icon-pos {
  padding-left: 8px;
}
.advance-targeting-modal .modal-body {
  padding-top: 5px !important;
  overflow: visible !important;
}
.advance-targeting-modal .ps-modal {
  overflow: visible;
}
.advance-targeting-modal .modal-body form .adv-tar-button {
  margin-top: 12px;
}

.psid-modal .ps-modal {
  padding-top: 1%;
}

.psid-modal .modal-body {
  display: table;
}

table.table.survey-detail-table
  tbody
  tr
  td
  .accordion-section:last-child
  .accordion-item
  h2.accordion-header {
  border-bottom: 0;
}
table.table.survey-detail-table
  tbody
  tr
  td
  .accordion-section:last-child
  .accordion-item {
  border: 0 !important;
}

.header-dropdown .dropdown-menu.show .item.py-2 {
  padding: 12px 35px !important;
  display: table;
}

.header-dropdown .dropdown-menu.show .col.condition-feasibility {
  width: auto;
}
.header-dropdown .dropdown-menu.show .item.py-2 .row.body {
  display: table-row;
  justify-content: space-between;
  align-items: center;
}
.header-dropdown .dropdown-menu.show .item.py-2 .row.body .col {
  display: table-cell;
  text-align: left;
  table-layout: fixed;
  overflow: hidden;
  word-wrap: break-word;
  padding-left: 20px;
  padding-bottom: 2px !important;
  padding-top: 2px !important;
  height: 27px;
}
.ps-modal-advanced-leave-as-is.modal-body {
  overflow: auto !important;
}
.padd-min-2 {
  margin: 7% 21% 0% 22%;
  font-size: 15px;
}

.report-dropdown .dropdown-menu.show {
  padding: 4px 0px !important;
  min-width: 10px !important;
  left: -15px;
}
.report-title-spacing {
  margin-bottom: 40px;
}
.tp-2 {
  margin-right: -8px;
}

.toast-container .toaster-text-bold {
  font-size: medium;
}
