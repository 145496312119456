.table-absolute table.table {
  position: absolute;
  left: 0;
  width: 100%;
}

table.table thead tr {
  background-color: #cceacc;
}

table.table thead tr th {
  vertical-align: middle;
  cursor: pointer;
  border: 0 !important;
  padding: 10px 18px;
}

table.table tr td {
  vertical-align: middle;
  border: 0 !important;
  padding: 10px 18px;
}

table.table tbody {
  border-top: none !important;
}

table.table tbody tr.odd {
  background-color: #f9f9f9;
}

table.table tfoot tr {
  vertical-align: middle;
  background-color: #f2dede !important;
}

table.table thead th.sorting-arrow:after {
  content: '\f0dc';
  font-family: FontAwesome;
  padding-left: 4px;
}

table.table thead th.sorting-arrow[data-order='asc']:after {
  content: '\f0de';
  font-family: FontAwesome;
  padding-left: 4px;
}

table.table thead th.sorting-arrow[data-order='desc']:after {
  content: '\f0dd';
  font-family: FontAwesome;
  padding-left: 4px;
}
